import React from "react";
import { Trans } from "react-i18next";
import { Link } from "gatsby";

export default function ContactUs() {
  return (
    <div className="flex flex-col items-center justify-center bg-gradient-to-r from-primaryBlue to-primaryGreen py-24">
      <h1 className="mb-4 text-5xl text-white">
        <Trans>Contact Us</Trans>
      </h1>
      <div className="mb-8 text-white">
        <Trans>Send us a message, let's grow together!</Trans>
      </div>
      <Link to="/contact-us">
        <button className="border border-white py-2 px-8 text-white transition-all hover:scale-105 hover:bg-white hover:bg-opacity-10">
          <Trans>Contact Us</Trans>
        </button>
      </Link>
    </div>
  );
}
