import * as React from "react";
import Seo from "../components/default/seo";
import Layout from "../components/common/Layout";
import PageHeader from "../components/common/PageHeader";
import ContactUs from "../components/common/ContactUs";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageHeader title="404" />
    <ContactUs />
  </Layout>
);

export default NotFoundPage;
