import React from "react";
import clsx from "clsx";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

export default function PageHeader({ text, title, img }) {
  return (
    <section className="relative bg-primaryBlue">
      <div
        className={clsx(
          "relative z-10 mx-auto flex max-w-5xl flex-col justify-center px-8 text-white lg:px-0",
          {
            ["h-[250px]"]: !img,
            ["h-[600px]"]: img,
          }
        )}
      >
        <div className="flex flex-col justify-center gap-8 text-xl md:max-w-lg md:px-0">
          <h1 className="text-6xl">{title}</h1>
          {text && <p className="font-bold">{text}</p>}
        </div>
      </div>
      {img && (
        <div className="absolute inset-0 h-full w-full object-cover">
          <GatsbyImage
            alt={title}
            image={img}
            layout="fullWidth"
            objectFit="cover"
            objectPosition="right"
            className="h-[600px] w-full object-cover"
          />
        </div>
      )}
    </section>
  );
}
